<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        div(class="right-content-border")
          button(@click="$router.push({ name: 'TopIndex' })" class="close-button") <i class="fas fa-times"></i>
          div(class="t-c")
            h5 現在のルーム名
            div.room-text {{ nameOld }}
          br
          div 変更後のルーム名
          div ※全角40文字、半角80文字以内
          textarea(v-model="nameNew", :maxlength="80" class="input-g-w-border" rows=3)

          component-error(:errors="errors" v-if="errors.length")
          div(class="button-gemini")
            button(@click="onSubmit()" class="pink-button") 変更する
            button(@click="onCancel()" class="pink-button") キャンセル

    component-footer-index
</template>

<style src="./Normal.scss" lang="scss" scoped>
</style>

<script>
import { getRoom, setRoom } from '@/api/room'
import { validStringCount } from '@/utils/inputLimit';

export default {
  name: "SettingRoomNameNormal",
  components: {},
  data() {
    return {
      nameOld: '',
      nameNew: '',
      data: {
      },
      errors: [],
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.syncData();
  },
  updated() {},
  destroyed() {},
  methods: {
    async syncData() {
      const ret = await getRoom();
      this.data = ret.data;
      this.nameOld = this.data.name;
    },
    async onSubmit() {
      if (!this.checkValidation()) { return }

      await setRoom({
        name: this.nameNew
      });
      this.$router.push({ name: 'TopIndex' })
    },
    onCancel() {
      this.$router.push({ name: 'TopIndex' })
    },
    checkValidation() {
      this.errors = [];
      let errorStrings = [];

      if (!this.nameNew) {
        errorStrings.push('※[変更後のルーム名]が入力されていません');
      }
      if (!validStringCount(this.nameNew,80)) {
        errorStrings.push('※[変更後のルーム名]は全角40文字、半角80文字以内で入力してください');
      }
      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }
      return true;
    },
  }
};
</script>
